import { gql } from '@apollo/client';

const USER_LOGIN = gql`
  mutation loginUser($user: UsersPermissionsLoginInput!) {
    login(input: $user) {
        jwt
        user {
          username
          email
        }        
    }
  }
`;

const USER_CREATE = gql`
  mutation saveUser($user: UsersPermissionsRegisterInput!) {
    register(input: $user) {
        jwt
        user {
          username
          email
        }
    }
  }
`;


export {USER_CREATE, USER_LOGIN}