import React from 'react';
import { Link } from 'react-router-dom';
import { getAPIurl } from '../utils/environment';

const StoryCard = ({art}) => {
    return (
        <div className="item">
        <div className="inner">
            <div className="img-holder">
                {art.photo && <img src={getAPIurl()+art.photo.formats.small.url} alt={art.photo.alternativeText}/>}
            </div>
            <div className="overlay">
                <div className="overlay-content">
                    <h3><Link to={"/pribeh/"+art.url}>{art.name}</Link></h3>
                    <div className="goal-raised">
                        <Link to={"/pribeh/"+art.url} className="donate-btn"><i className="fi flaticon-heart-1"></i>Chci vidět více</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default StoryCard;