
export const CART_ADD = 'CART_ADD'
export const CART_REMOVE = 'CART_REMOVE'
export const CART_CHANGE_QUANTITY = 'CART_CHANGE_QUANTITY'
export const CART_CLEAR = 'CART_CLEAR'
export const CART_ADD_VISITED = 'CART_ADD_VISITED'

export const cartAdd = (goodsId, quantity) => {
    return (dispatch) => {
        dispatch({
            type: CART_ADD,
            data: { goodsId, quantity: parseInt(quantity)}
        })
    }    
}

export const cartChangeQuantity = (goodsId, newQuantity) => {
    return (dispatch) => {
        dispatch({
            type: CART_CHANGE_QUANTITY,
            data: { goodsId, quantity: parseInt(newQuantity)}
        })
    }    
}

export const cartRemove = (goodsId) => {
    return (dispatch) => {
        dispatch({
            type: CART_REMOVE,
            data: goodsId
        })
    }    
}

export const cartClear = () => {
    return (dispatch) => {
        dispatch({
            type: CART_CLEAR
        })
    }    
}

export const cartAddVisited = () => {
    return (dispatch) => {
        dispatch({
            type: CART_ADD_VISITED
        })
    }    
}

