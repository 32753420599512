import React from 'react';
import { Link } from 'react-router-dom';

const PageTitle = (props) => {

  return (
    <section className="page-title">
        <div className="page-title-container">
            <div className="page-title-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <h2>{props.title}</h2>
                            <ol className="breadcrumb">
                                <li><Link to="/">Úvod</Link></li>
                                {props.crumbs && props.crumbs.map((crmb) => <li key={crmb.url}><Link to={crmb.url}>{crmb.title}</Link></li>)}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default PageTitle;

