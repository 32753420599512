import { gql } from '@apollo/client';
import { VOUCHER_FIELDS } from './voucher';

const CART_CALC = gql`
${VOUCHER_FIELDS}
query calcCart($items : [CartItemInput]) {
  calcCart(items: $items) {
    totalPrice
    available
    vouchers {
      ...VoucherFields
    }
  }
}
`;

export {CART_CALC}