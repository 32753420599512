import React from 'react';
import { useQuery } from '@apollo/client';
import { ARTICLE_STORIES } from '../queries/article';
import StoriesCarousel from '../components/StoriesCarousel';

const StoriesCarouselBlock = ({onlyHP}) => {
  const { loading, data } = useQuery(ARTICLE_STORIES, {variables: onlyHP ? { ishp: true} : {}});

  return (
      <>
    {!loading && data && data.articles && 
    <StoriesCarousel data={data} />}
    </>
  );
}

export default StoriesCarouselBlock;
