import { gql } from '@apollo/client';

const ARTICLE_FIELDS = gql`
  fragment ArticleFields on Article {
    id
    name
    label
    content
    url
    photo {
      formats
    }
    links {
      typ
      url
    }
    updatedAt
    vouchers {
      id
      name
      label
      description
      url
      price
      gallery {
        id
        formats
      }
      partner {
        id
        name
      }
      availability
    }
  }
`;

const ARTICLE_LIST_FIELDS = gql`
  fragment ArticleListFields on Article {
    id
    name
    label
    url
    photo {
      formats
    }
  }
`;

const ARTICLE_DETAIL = gql`
  ${ARTICLE_FIELDS}
  query getArticle($url: String) {
    articles(where: {url: $url}) {
      ...ArticleFields
    }
  }
`;

const ARTICLE_STORIES = gql`
  ${ARTICLE_LIST_FIELDS}
  query getStories($ishp: Boolean) {
    articles(where: {type: "story", is_hp : $ishp}) {
      ...ArticleListFields
    }
  }
`;

export {ARTICLE_DETAIL, ARTICLE_STORIES, ARTICLE_FIELDS, ARTICLE_LIST_FIELDS}