import React from 'react';

const Preloader = () => {

    return (
    <div className="preloader">
        <div className="preloader-inner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>
    </div>)

  }

export default Preloader;