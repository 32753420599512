import React from 'react';
import PageTitle from '../components/layout/PageTitle';
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { USER_CREATE } from '../queries/user';

const Registration = () => {


    const { register, watch, handleSubmit } = useForm();
    const [userCreate] = useMutation(USER_CREATE);

    const onSubmit = dt => {
        userCreate({variables: { user: {username: dt.username, email: dt.email ,password: dt.password }}})
    }

    let passToCheck = watch("password")

  return (
    <>
        <PageTitle title={"Registrace"} crumbs={[]}/>
        <section className="contact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
                        <div className="section-title-s3">
                            <span>#Registrace</span>
                            <h2>Zapojte se do dění!</h2>
                            <p>Registrací získáte možnost zapojit se do projektu a ....</p>
                        </div>
                    </div>
                </div>                
                <div className="contact-form-area">
                    <div className="row">
                        <div className="col col-md-4">
                            <div className="contact-text">
                                <h3>Vytvoření uživatelského účtu</h3>
                                <p>je jednoduché a v prvním kroku stačí zadat email a zvolené heslo.</p>
                            </div>
                        </div>
                        <div className="col col-md-8">
                            <div className="contact-form">
                                <form method="post" className="contact-validation-active" id="contact-form-main" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <input ref={register} type="text" className="form-control" name="username" id="username" placeholder="Uživatelské jméno *" />
                                    </div>
                                    <div>
                                        <input ref={register} type="email" className="form-control" name="email" id="email" placeholder="Email *" />
                                    </div>
                                    <div>
                                        <input ref={register} type="password" className="form-control" name="password" id="password" placeholder="Heslo*" />
                                    </div>
                                    <div>
                                        <input ref={register({
                                            required: true,
                                            validate: value => value === passToCheck
                                        })} type="password" className="form-control" name="password2" id="password2" placeholder="Heslo pro kontrolu*" 
                                        />
                                    </div>
                                    <div className="submit-area">
                                        <button type="submit" className="theme-btn-s6"><i className="fi flaticon-like"></i> Odeslat</button>
                                        <div id="loader">
                                            <i className="ti-reload"></i>
                                        </div>
                                    </div>
                                    <div className="clearfix error-handling-messages">
                                        <div id="success">Thank you</div>
                                        <div id="error"> Error occurred while sending email. Please try again later. </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>        
    </>
  );
}

export default Registration;
