
export const AUTH_SAVE_DATA = 'AUTH_SAVE_DATA'
export const AUTH_CLEAR_DATA = 'AUTH_CLEAR_DATA'

export const authSaveData = (data) => {
    return (dispatch) => {
        dispatch({
            type: AUTH_SAVE_DATA,
            data: data
        })
    }    
}

export const authClearData = () => {
    return (dispatch) => {
        dispatch({
            type: AUTH_CLEAR_DATA
        })
    }    
}
