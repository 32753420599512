import React from 'react';

const ParameterName = ({type}) => {

    let paramName = null
    switch (type) {
        case "range":
            paramName = "Rozsah"
            break;
        case "validity":
            paramName = "Platnost"
            break;
        case "persons":
            paramName = "Počet osob"
            break;            
        default:
            break;
    }
    return (<>{paramName}</>)

  }

export default ParameterName;