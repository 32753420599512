import { gql } from '@apollo/client';

const PARTNER_FIELDS = gql`
  fragment PartnerFields on Partner {
    id
    name
    url
  }
`;

const PARTNER_LIST = gql`
  ${PARTNER_FIELDS}
  query getPartnerList {
    partners {
      ...PartnerFields
    }
  }
`;


export {PARTNER_LIST}