import { CART_ADD, CART_ADD_VISITED, CART_CHANGE_QUANTITY, CART_CLEAR, CART_REMOVE } from "../actions/cart";

const iniState = {
    items: [],
    added: false
}
const cartReducer = (state = iniState, action) => {
    switch (action.type) {
        case CART_ADD:
            let newItems = [...state.items]
            let alreadyExists = false
            newItems = newItems.map((itm) => {
                if(itm.goodsId === action.data.goodsId) {
                    alreadyExists = true
                    return { ...itm, quantity: itm.quantity + action.data.quantity}                    
                } else {
                     return itm
                }
            })
            if(!alreadyExists) {
                newItems.push(action.data)
            }
            return {
                ...state,
                added: true,
                items: newItems
            }
        case CART_CHANGE_QUANTITY:
            let newItemsQ = [...state.items]
            newItemsQ = newItemsQ.map((itm) => {
                if(itm.goodsId === action.data.goodsId) {
                    return { ...itm, quantity: action.data.quantity}                    
                } else {
                    return itm
                }
            })
            return {
                ...state,
                items: newItemsQ
            }
        case CART_REMOVE:
            let newItemsR = [...state.items]
            newItemsR = newItemsR.filter((itm) => itm.goodsId !== action.data)
            return {
                ...state,
                items: newItemsR
            }
        case CART_ADD_VISITED:
            return {
                ...state,
                added: false
            }                        
        case CART_CLEAR:
            return iniState
        default:
            return state;
    }
}

export default cartReducer;