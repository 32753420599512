import moment from 'moment'

const localizedDate = (date, format, lang) => {
    moment.locale(lang)
    return moment(date).format(format)
}

const formattedDate = (date, format) => {
    return moment(date).format(format)
}

export {localizedDate, formattedDate}