import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import ExternalRedirect from '../components/ExternalRedirect';
import PageTitle from '../components/layout/PageTitle';
import Preloader from '../components/Preloader';
import { CART_CALC } from '../queries/cart';
import { ORDER_CREATE } from '../queries/order';
import { cartChangeQuantity, cartClear, cartRemove } from '../redux/actions/cart';
import { getAPIurl } from '../utils/environment';

const Cart = () => {

    let {cartItems} = useSelector(mapStateToProps) 
    const { loading, data } = useQuery(CART_CALC, {variables: { items: cartItems}});

    const { register, errors, handleSubmit } = useForm({
        defaultValues: {
            order: {
                payment_type: "quick"
            }
        }
    });

    let dflt = {}
    cartItems.map((ci) => {
        dflt[ci.goodsId] = ci.quantity
        return ci
    })

    const { register: registerCart, getValues, setValue, handleSubmit: handleSubmitCart } = useForm();

    const [orderCreate, {loading: mutationLoading, error: mutationError}] = useMutation(ORDER_CREATE);
    const [orderRedirect, setOrderRedirect] = useState({external: false, url: null});
    let dispatch = useDispatch()

    const onSubmit = dt => {

        dt.order.items = cartItems.filter((item) => {
            return data && data.calcCart && data.calcCart.vouchers && data.calcCart.vouchers.filter((vouch) => vouch.id === item.goodsId).length > 0
        }).map((item) => {
            let voucherData = data && data.calcCart && data.calcCart.vouchers && data.calcCart.vouchers.filter((vouch) => vouch.id === item.goodsId)[0]
            return {voucher: item.goodsId, amount: item.quantity, price: voucherData.price}
        })      

        orderCreate({variables: { order: dt.order, customer: dt.customer}}).then((payload) => {
            dispatch(cartClear())
            if(payload.data.sendOrder && payload.data.sendOrder.payment && payload.data.sendOrder.payment.redirect) {
                setOrderRedirect({external: true, url: payload.data.sendOrder.payment.redirect})
            } else {
                setOrderRedirect({external: false, url: payload.data.sendOrder && payload.data.sendOrder.order && "/objednavka/"+payload.data.sendOrder.order.id})
            }
        }).catch((err) => {
            console.log(err)
        })

    }

    const onSubmitCart = dt => {
        for (var key in dt.productCount) {
            if (dt.productCount.hasOwnProperty(key) && dflt[key] !== parseInt(dt.productCount[key])) {
                dispatch(cartChangeQuantity(key, dt.productCount[key]))
            }
            }
    }

    const removeFromCart = (id) => dispatch(cartRemove(id))

    const cartPlus = (id) => {
        let val = parseInt(getValues("productCount."+id))
        setValue("productCount."+id, val + 1)
        handleSubmitCart(onSubmitCart)()
    }

    const cartMinus = (id) => {
        let val = parseInt(getValues("productCount."+id))
        if(val > 1) {
            setValue("productCount."+id, val - 1)
            handleSubmitCart(onSubmitCart)()
        }
    }

  return (
    <>
        {(loading || mutationLoading) && <Preloader />}

        {orderRedirect && orderRedirect.external === false && orderRedirect.url && <Redirect to={orderRedirect.url} />}
        {orderRedirect && orderRedirect.external === true && orderRedirect.url && <ExternalRedirect url={orderRedirect.url} />}

        <PageTitle title={"Košík"} crumbs={[]}/>

        <section className="case-single-section">
            <div className="container">
                <div className="row donate-area-bottom">
                <div className="col col-md-4 col-md-push-8">
                        <h3>Položky</h3>
                        <div className="case-single-sidebar">
                            <div className="widget urgent-case-widget">
                            <form method="post" className="form" onSubmit={handleSubmitCart(onSubmitCart)}>
                                <div className="cases">
                                  {cartItems.map((item) => {
                                  let voucherData = data && data.calcCart && data.calcCart.vouchers && data.calcCart.vouchers.filter((vouch) => vouch.id === item.goodsId)[0]
                                  return (<div className="case" key={item.goodsId} >
                                    {voucherData && <>
                                        <div className="img-holder">
                                        <img src={getAPIurl()+voucherData.gallery.filter((img, idx) => idx === 0)[0].formats.thumbnail.url} alt={voucherData.gallery.filter((img, idx) => idx === 0)[0].alternativeText}/>
                                        </div>
                                        <div className="details">
                                            <h4><Link to={"/dobrovoucher/"+voucherData.url}>{voucherData.name}</Link></h4>
                                            <span className="g-r">{voucherData.price} Kč</span>
                                            <div className="form-inline form-quantity">
                                                <div className="form-group">
                                                    <div className="input-group" style={{width: 95}}>
                                                        <span className="input-group-addon"><button onClick={() => cartMinus(item.goodsId)}>-</button></span>
                                                        <input type="number" name={"productCount."+item.goodsId} className="form-control" ref={registerCart({min: 1, required: true})} onBlur={(e) => {
                                                            handleSubmitCart(onSubmitCart)()
                                                        }} defaultValue={item.quantity}/>
                                                        <span className="input-group-addon"><button onClick={() => cartPlus(item.goodsId)}>+</button></span>
                                                    </div>
                                                    <span>ks</span>
                                                    <button onClick={() => removeFromCart(item.goodsId)}><i className="ti-close"></i></button>
                                                </div>
                                            </div>
                                        </div>            
                                      </>}</div>)
                                  })}
                                <div className="case">
                                    <div className="img-holder"><strong>Celkem:</strong> </div>

                                    <div className="details">
                                        <div className="summary">{(data && data.calcCart && data.calcCart.totalPrice) || 0} Kč</div>
                                            {/*<button type="submit" className="theme-btn-s6"><i className="fi flaticon-replay-arrow"></i>Přepočítat</button>*/}
                                        </div>
                                </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>                    
                    <div className="col col-md-8 col-md-pull-4">
                        <h3>Objednávka</h3>
                        <div className="donate-area-wrapper">
                            <form id="give-form-232-1" className="give-form give-form-232 give-form-type-multi" method="post" onSubmit={handleSubmit(onSubmit)}>
                                <div id="give_purchase_form_wrap">
                                    <fieldset id="give_checkout_user_info" className="">
                                        <legend> Osobní údaje</legend>
                                        <p id="give-first-name-wrap" className="form-row form-row-first form-row-responsive">
                                            <label className="give-label" htmlFor="give-first"> Jméno
                                                <span className="give-required-indicator">*</span>
                                            </label>
                                            <input ref={register} className="give-input required" type="text" name="customer.name" autoComplete="given-name" placeholder="Jméno" id="give-first"
                                            required aria-required="true" />
                                        </p>

                                        <p id="give-last-name-wrap" className="form-row form-row-last form-row-responsive">
                                            <label className="give-label" htmlFor="give-last"> Příjmení                               
                                              <span className="give-required-indicator">*</span>
                                            </label>

                                            <input ref={register} className="give-input" type="text" name="customer.surname" autoComplete="family-name" id="give-last" placeholder="Příjmení" required aria-required="true" />
                                        </p>

                                        <p id="give-email-wrap" className="form-row form-row-first form-row-responsive">
                                            <label className="give-label" htmlFor="give-email">
                                            Emailová adresa
                                                <span className="give-required-indicator">*</span>
                                            </label>
                                            <input ref={register} className="give-input required" type="email" name="customer.email" autoComplete="email" placeholder="Emailová adresa" id="give-email"
                                            required aria-required="true" />
                                        </p>
                                        <p id="give-email-wrap" className="form-row form-row-last form-row-responsive">
                                            <label className="give-label" htmlFor="give-phone">
                                            Telefon
                                                <span className="give-required-indicator">*</span>
                                            </label>
                                            <input ref={register} className="give-input required" type="text" name="customer.address.phone" autoComplete="tel-local" placeholder="Telefon" id="give-phone"
                                            required aria-required="true" />
                                        </p>                                          

                                        <p id="give-first-name-wrap" className="form-row form-row-wide">
                                            <label className="give-label" htmlFor="give-street"> Ulice a číslo
                                                <span className="give-required-indicator">*</span>
                                            </label>
                                            <input ref={register} className="give-input required" type="text" name="customer.address.street" autoComplete="email" placeholder="Ulice a číslo" id="give-street"
                                            required aria-required="true" />
                                        </p>

                                        <p id="give-last-name-wrap" className="form-row form-row-first form-row-responsive">
                                            <label className="give-label" htmlFor="give-zip"> PSČ                               
                                              <span className="give-required-indicator">*</span>
                                            </label>

                                            <input ref={register} className="give-input" type="text" name="customer.address.zip" id="give-zip" autoComplete="postal-code" placeholder="PSČ" required aria-required="true" />
                                        </p>

                                        <p id="give-last-name-wrap" className="form-row form-row-last form-row-responsive">
                                            <label className="give-label" htmlFor="give-city"> Město                               
                                              <span className="give-required-indicator">*</span>
                                            </label>

                                            <input ref={register} className="give-input" type="text" name="customer.address.city" id="give-city" autoComplete="address-level2" placeholder="Město" required aria-required="true" />
                                        </p>

                                        <p id="give-comment-wrap" className="form-row form-row-wide">
                                            <label className="give-label" htmlFor="give-comment">
                                            Poznámka                                     
                                                <span className="give-tooltip hint--top hint--medium hint--bounce" aria-label="Doplňte případné detaily k Vaší objednávce." rel="tooltip">
                                                    <i className="give-icon give-icon-question"></i>
                                                </span>             
                                            </label>

                                            <textarea ref={register} className="give-input" name="order.comment" placeholder="Poznámka k objednávce" id="give-comment"></textarea>
                                        </p>
                                    </fieldset>

                                    <fieldset id="give-payment-mode-select">
                                    <legend className="give-payment-mode-label">Vyberte platební metodu</legend>

                                    <div id="give-payment-mode-wrap">
                                        <ul id="give-gateway-radio-list">
                                            <li>
                                                <input ref={register({required: true})} type="radio" name="order.payment_type" className="give-gateway" id="give-gateway-offline-232-2" value="quick" />

                                                <label htmlFor="give-gateway-offline-232-2" className="give-gateway-option" id="give-gateway-option-offline"> Bankovním převodem vč. online platebních tlačítek</label>
                                            </li>
                                        </ul>
                                        {errors.confirmConditions && <div className="alert alert-danger">Je potřeba vybrat způsob platby</div>}
                                    </div>
                                </fieldset>                                    
                                <hr />      
                                    <p id="give-anonymous-donation-wrap" className="form-row form-row-wide">
                                          <label className="give-label" htmlFor="give-conditions">
                                              <input ref={register({required: true})} type="checkbox" className="give-input" name="confirmConditions" id="give-conditions" value="1" /> Souhlasím s <Link to="/clanek/obchodni-podminky" target="_blank" rel="noopener noreferrer">obchodními podmínkami</Link>
                                          </label>
                                      </p>        
                                      {errors.confirmConditions && <div className="alert alert-danger">Je potřeba souhlasit s podmínkami</div>}

                                    <fieldset id="give_purchase_submit" className="give-donation-submit">
                                        <p id="give-final-total-wrap" className="form-wrap ">
                                            <span className="give-donation-total-label"> Celková cena:</span>
                                            <span className="give-final-total-amount">{(data && data.calcCart && data.calcCart.totalPrice) || 0} Kč</span>
                                        </p>
                                        <div className="give-submit-button-wrap give-clearfix">
                                            <input type="submit" className="give-submit give-btn" id="give-purchase-button" name="give-purchase" value="Objednat" />
                                        </div>
                                        {mutationError && <div className="alert alert-danger">Došlo k chybě v ukládání objednávky!!! Zkuste to prosím znovu.</div>}
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>        
    </>
  );
}

const mapStateToProps = (state) => {
  return {
      cartItems: state.cart.items
  }
}

export default Cart;
