
import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../components/layout/PageTitle';
import Preloader from '../components/Preloader';
import { ORDER_DETAIL } from '../queries/order';

const Order = () => {

    let { id, status } = useParams();
    const { loading, data } = useQuery(ORDER_DETAIL, {variables: { id }});

  return (
    <>
        {loading && <Preloader />}

        <PageTitle title={"Objednávka dokončena"} crumbs={[]}/>

        {data && data.order && <section className="contact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
                        <div className="section-title-s3">
                            <span>#Objednávka č. {data.order.order_no}</span>
                            {(status === "OK") && 
                            <>
                            <h2>Objednávka byla úspěšně dokončena a platba je potvrzena!</h2>
                            <p>Objednávku nyní předáváme ke zpracování. O dalším postupu Vás budeme informovat.</p>                 
                            </>
                            }
                            {(status === "CNCL") && 
                            <>
                            <h2>Objednávka byla uložena, ale platba nebyla úspěšně dokončena!</h2>           
                            <p>Objednávku nebudeme nyní dále zpracovávat. V případě, že platbu dokončíte na odkaze obdrženém emailem bude zpracování pokračovat.</p>                 
                            </>
                            }                            
                            {(!status || status === "NA") && 
                            <>
                            <h2>Objednávka byla úspěšně dokončena. Na platbu ale zatím čekáme.</h2>
                            <p>Objednávka bude následně po obdržení platby zpracována. O dalším postupu Vás budeme informovat.</p>                 
                            </>
                            }                            
                        </div>
                    </div>
                </div>
            </div>
        </section>}            
    </>
  );
}

export default Order;
