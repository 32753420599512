import React from 'react';
import { useQuery } from '@apollo/client';

import { VOUCHERS_FILTERABLE_LIST } from '../queries/voucher';
import PageTitle from '../components/layout/PageTitle';
import Preloader from '../components/Preloader';
import VoucherCard from '../components/VoucherCard';

const VoucherTopList = () => {

    const { loading, data } = useQuery(VOUCHERS_FILTERABLE_LIST, {variables: {
        istop: true
    }});
    
    return (
        <>
            {(loading) && <Preloader />}

            <PageTitle title={"TOP DOBROvouchery"} crumbs={[{title: "TOP DOBROvouchery", url: "/top-dobrovouchery"}]}/>
            
            <section className="shop-pg-section section-padding">
            <div className="container">
                <div className="row">           
                    <div className="col col-md-12">
                        {data && data.vouchers && data.vouchers.length > 0 && 
                        <div className="shop-grids">
                            {data.vouchers.map((vouch) => <VoucherCard vouch={vouch} key={vouch.id} />)}
                        </div>} 
                    </div>

                </div>
            </div>
        </section>
        </>
    );
}

export default VoucherTopList;
