import React from 'react';
import Carousel, { slidesToShowPlugin, slidesToScrollPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import StoryCard from '../components/StoryCard';

const StoriesCarousel = ({data}) => {

  return (
      <>
    {data && data.articles && 
<Carousel className="content-area causes-slider"
  plugins={[
     'centered',
     'arrows',
    {
      resolve: slidesToShowPlugin,
      options: {
       numberOfSlides: 3,
      },
    },
    {
      resolve: slidesToScrollPlugin,
      options: {
       numberOfSlides: 1,
      },
    },
  ]}
  breakpoints={{
    640: {
      plugins: [
       {
         resolve: slidesToShowPlugin,
         options: {
          numberOfSlides: 1
         }
       },
     ]
    },
    768: {
      plugins: [
       {
         resolve: slidesToShowPlugin,
         options: {
          numberOfSlides: 2
         }
       },
     ]
    }
  }}   
>
    {data.articles.map((art) =>
      <StoryCard key={art.id} art={art} />
    )}
</Carousel>}
    </>
  );
}

export default StoriesCarousel;
