import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import showdown from 'showdown';

import PageTitle from '../components/layout/PageTitle';
import Preloader from '../components/Preloader';
import { getAPIurl } from '../utils/environment';

import { ARTICLE_DETAIL } from '../queries/article';
import { formattedDate } from '../utils/conversions';
import VoucherCard from '../components/VoucherCard';

const converter = new showdown.Converter()

const ArticleDetail = () => {

  let { url } = useParams();

  const { loading, data } = useQuery(ARTICLE_DETAIL, {variables: { url }});
  let article = data && data.articles && data.articles[0]

  return (
    <>
        {loading && <Preloader />}

        {article && <PageTitle title={article.name} crumbs={[]}/>}
      
        {article &&
        <section className="blog-single-section section-padding">
        <div className="container">
            <div className="row">
                <div className="col col-md-10 col-md-offset-1">
                    <div className="blog-content">
                        <div className="post format-standard-image">
                            {article.photo && article.photo.formats.large &&
                            <div className="entry-media">
                                <img src={getAPIurl()+article.photo.formats.large.url} alt={article.photo.alternativeText}/>
                            </div>}
                            <div className="date-entry-meta">
                                <div className="entry-meta">
                                    <span>Aktualizace: {formattedDate(article.updatedAt, 'DD.MM.YYYY HH:mm')}</span>
                                </div>
                            </div>
                            <h2>{article.label}</h2>
                            <div dangerouslySetInnerHTML={{__html: converter.makeHtml(article.content)}}></div>
                        </div>
                        
                        <div className="tag-share clearfix">
                            <div className="tag">
                                <ul>
                                    {article.links.filter((lnk) => lnk.typ === "web").map((lnk) => <li key={lnk.typ}><a href={lnk.url}>Webové stránky</a></li>)}
                                </ul>
                            </div>
                            <div className="share">
                                <ul>
                                    {article.links.filter((lnk) => lnk.typ !== "web").map((lnk) => <li key={lnk.typ}><a href={lnk.url}><i className={"ti-"+ lnk.typ + (lnk.typ === "twitter" ? "-alt": "")}></i></a></li>)}
                                </ul>
                            </div>
                        </div>

                    </div>                        
                </div>
            </div>
        </div>
    </section>} 

    {article && article.vouchers && article.vouchers.length > 0 &&
    <section className="shop-pg-section">
        <div className="container">
        <div class="section-title-s2">
            <span>#DOBROVOUCHERY</span>
                </div>
            <div className="row">
                <div className="col col-md-8 col-md-offset-2">
                    <div className="shop-grids">
                        {article.vouchers.map((vouch) => <VoucherCard vouch={vouch} key={vouch.id} />)}
                    </div>
                </div>        
            </div>        
        </div>        
    </section>}
    </>
  );
}

export default ArticleDetail;
