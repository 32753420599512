import { gql } from '@apollo/client';

const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    id
    order_no
    status
    payed
    createdAt
    items {
      voucher {
        id
        name
      }
      amount
      price
    }
  }
`;

const ORDER_DETAIL = gql`
  ${ORDER_FIELDS}
  query getOrder($id: ID!) {
    order(id: $id) {
      ...OrderFields
    }
  }
`;

const ORDER_CREATE = gql`
  ${ORDER_FIELDS}
  mutation sendOrder($order: OrderInput, $customer: CustomerInput) {
    sendOrder(order: $order, customer: $customer) {
      order {
        ...OrderFields
      }
      payment {
          code
          message
          redirect
      }
    }
  }
`;


export {ORDER_DETAIL, ORDER_CREATE}