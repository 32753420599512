import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/flaticon.css';
import './assets/css/themify-icons.css';
import './assets/css/bootstrap.min.css';
import './assets/css/give.css';
import './assets/sass/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import {createStore, applyMiddleware, compose} from 'redux'
import rootReducer from './redux/reducers'
import thunkMiddleware from 'redux-thunk'
import {Provider} from 'react-redux'
import {save, load} from "redux-localstorage-simple"
import { getAPIurl } from './utils/environment';

const client = new ApolloClient({
  uri: getAPIurl() + '/graphql',
  cache: new InMemoryCache()
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, load({states: ['cart', 'auth']}), composeEnhancers(
    applyMiddleware(thunkMiddleware, save({states: ['cart', 'auth']}))
))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
