import React from 'react';
import { useQuery } from '@apollo/client';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Link } from 'react-router-dom';
import { VOUCHERS_FILTERABLE_LIST } from '../queries/voucher';
import { getAPIurl } from '../utils/environment';

SwiperCore.use([Navigation, Pagination]);

const HomepageCarousel = () => {
  const { loading, data } = useQuery(VOUCHERS_FILTERABLE_LIST, {variables: { ishp: true}});

  return (
      <>
    {!loading && <section className="hero-slider hero-style-2">
        <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev"}}
        pagination={{ type: "fraction" }}
        >
            {data && data.vouchers.map(({ id, name, label, url, gallery, partner }) => 
            <SwiperSlide key={id}>
                <div className="slide-inner slide-bg-image" style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url("+getAPIurl()+gallery.filter((img, idx) => idx === 0)[0].formats.large.url+")"}}>
                    <div className="container">
                        <div data-swiper-parallax="300" className="slide-title">
                            <h2>{name}</h2>
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                            <p>{label}</p>
                        </div>
                        <div className="clearfix"></div>
                        <div data-swiper-parallax="500" className="slide-btns">
                            <Link to={"/dobrovoucher/"+url} className="theme-btn-s2">Chci vidět více <i className="fi flaticon-heart-1"></i></Link>
                        </div>
                    </div>
                </div> 
            </SwiperSlide>
            )}
            <span slot="container-end">
                <div className="container">
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </span>
        </Swiper>
    </section>}
    </>
  );
}

export default HomepageCarousel;
