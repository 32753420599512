import React, { useState } from 'react';
import {Grid} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/dobro-logo-tyrkys_symbol.png';
import useComponentVisible from '../../utils/visibleComponent';
import MiniCart from '../../blocks/MiniCart';
import { getAPIurl } from '../../utils/environment';
import { authClearData } from '../../redux/actions/auth';

const Header = () => {

   const [cartRef, isCartVisible, setCartVisible] = useComponentVisible(false);
   let dispatch = useDispatch()

   const [mobileOpen, setMobileOpen] = useState(false)

   let {cartCount, userData} = useSelector(mapStateToProps)

   const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(authClearData())
    };

  return (
    <header id="header" className="site-header header-style-2">
        <nav className="navigation navbar navbar-default">
          <Grid fluid>
            <div className="navbar-header">
                <button type="button" className="open-btn" onClick={() => setMobileOpen(!mobileOpen)}>
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <Link className="navbar-brand" to="/" onClick={() => setMobileOpen(false)}><img src={Logo} alt="" /></Link>
                <h1>DOBRO</h1>
            </div>
            <div id="navbar" className={"navbar-collapse collapse navbar-left navigation-holder"+(mobileOpen ? " slideInn":"")}>
              <button className="close-navbar" onClick={() => setMobileOpen(false)}><i className="ti-close"></i></button>
              <ul className="nav navbar-nav">
                  <li><Link to="/dobrovouchery" onClick={() => setMobileOpen(false)}>Vouchery</Link></li>
                  <li><Link to="/pribehy" onClick={() => setMobileOpen(false)}>Druzi</Link></li>
                  <li><Link to="/clanek/dobroandta" onClick={() => setMobileOpen(false)}>&amp;TA</Link></li>
                  <li><Link to="/partneri">Partneři</Link></li>
                  <li className="menu-item-has-children cart-search-contact">
                      {userData && userData.username ?
                      <>
                      <Link to="/odhlaseni" onClick={() => setMobileOpen(false)}><i className="fi flaticon-user" /> {userData.username}</Link>
                      <ul className="sub-menu">
                          <li><Link to="/objednavky" onClick={() => setMobileOpen(false)}>Objednávky</Link></li>
                          <li><a href="/#" onClick={(e) => logout(e)}>Odhlásit</a></li>
                      </ul>
                      </>
                      :
                      <>
                      <Link to="/prihlaseni" onClick={() => setMobileOpen(false)}><i className="fi flaticon-user" /> Účet</Link>
                      <ul className="sub-menu">
                          <li><Link to="/prihlaseni" onClick={() => setMobileOpen(false)}>Přihlášení</Link></li>
                          <li><Link to="/registrace" onClick={() => setMobileOpen(false)}>Registrace</Link></li>
                          <li><a href={getAPIurl()+"/connect/facebook"}><i className="ti-facebook"></i> Přihlásit přes Facebook</a></li>
                      </ul>
                      </>                      
                      }
                  </li>
                  <li className="cart-search-contact cart-search-contact-controls">
                      <div className="mini-cart">
                        <button className="cart-toggle-btn" onClick={() => setCartVisible(true)}> <i className="fi flaticon-shopping-basket"></i> {cartCount > 0 && <span className="cart-count">{cartCount}</span>}</button>
                        <MiniCart fRef={cartRef} visible={isCartVisible} setCartVisible={setCartVisible} />
                    </div>
                    <div className="mini-cart-btn">
                        <Link to="/kosik" className="view-cart-btn" onClick={() => setMobileOpen(false)}>Košík</Link>
                    </div>
                  </li>                  
              </ul>
          </div>
          </Grid>
        </nav>
    </header>

  );
}

const mapStateToProps = (state) => {
    return {
        cartCount: state.cart.items.length,
        userData: state.auth.userData
    }
  }

export default Header;
