import React from 'react';
import PageTitle from '../components/layout/PageTitle';
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { USER_LOGIN } from '../queries/user';
import { useDispatch } from 'react-redux';
import { authSaveData } from '../redux/actions/auth';

const Login = () => {

    const { register, handleSubmit } = useForm();
    const [userLogin] = useMutation(USER_LOGIN);
    let dispatch = useDispatch()

    const onSubmit = dt => {
        userLogin({variables: { user: {identifier: dt.email ,password: dt.password }}}).then((payload) => {
            dispatch(authSaveData(payload.data && payload.data.login))
        }).catch((err) => {
            console.log(err)
        })
    }

  return (
    <>
        <PageTitle title={"Přihlášení"} crumbs={[]}/>
        <section className="contact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
                        <div className="section-title-s3">
                            <span>#Přihlášení</span>
                            <h2>Zapojte se do dění!</h2>
                        </div>
                    </div>
                </div>                
                <div className="contact-form-area">
                    <div className="row">
                        <div className="col col-md-4">
                            <div className="contact-text">
                                <h3>Přihlášení k účtu</h3>
                                <p>pomocí emailu a hesla</p>
                            </div>
                        </div>
                        <div className="col col-md-8">
                            <div className="contact-form">
                                <form method="post" className="contact-validation-active" id="contact-form-main" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <input ref={register} type="email" className="form-control" name="email" id="email" placeholder="Email *" />
                                    </div>
                                    <div>
                                        <input ref={register} type="password" className="form-control" name="password" id="password" placeholder="Heslo*" />
                                    </div>
                                    <div className="submit-area">
                                        <button type="submit" className="theme-btn-s6"><i className="fi flaticon-like"></i> Přihlásit</button>
                                        <div id="loader">
                                            <i className="ti-reload"></i>
                                        </div>
                                    </div>
                                    <div className="clearfix error-handling-messages">
                                        <div id="success">Thank you</div>
                                        <div id="error"> Error occurred while sending email. Please try again later. </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>        
    </>
  );
}

export default Login;
