import { useQuery } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CART_CALC } from '../queries/cart';
import { getAPIurl } from '../utils/environment';

const MiniCart = (props) => {

    let {cartItems} = useSelector(mapStateToProps) 
    const { loading, data } = useQuery(CART_CALC, {variables: { items: cartItems}});

    return (
    <div ref={props.fRef} className={"mini-cart-content" + (props.visible ? " mini-cart-content-toggle" : "")}>
        {!loading && <>
        <div className="mini-cart-items">
        {cartItems.map((item) => {
            let voucherData = data && data.calcCart && data.calcCart.vouchers && data.calcCart.vouchers.filter((vouch) => vouch.id === item.goodsId)[0]
            return (<div key={item.goodsId} className="mini-cart-item clearfix">
                {voucherData && <>
            <div className="mini-cart-item-image">
                <Link to={"/dobrovoucher/"+voucherData.url}>
                    <img src={getAPIurl()+voucherData.gallery.filter((img, idx) => idx === 0)[0].formats.thumbnail.url} alt={voucherData.gallery.filter((img, idx) => idx === 0)[0].alternativeText}/>
                </Link>
            </div>
            <div className="mini-cart-item-des">
                <Link to={"/dobrovoucher/"+voucherData.url}>{voucherData.name}</Link>
                <span className="mini-cart-item-price">{voucherData.price}</span>
                <span className="mini-cart-item-quantity">x {item.quantity}</span>
            </div>
            </>}
        </div>)
        })}
        </div>
        <div className="mini-cart-action clearfix">
            <span className="mini-checkout-price">Celkem {data && data.calcCart && data.calcCart.totalPrice} Kč</span>
            <Link to="/kosik" className="view-cart-btn" onClick={() => props.setCartVisible(false)}>Košík</Link>
        </div>
        </>}
    </div>
    );
}

const mapStateToProps = (state) => {
    return {
        cartItems: state.cart.items
    }
  }

export default MiniCart;
