import { AUTH_CLEAR_DATA, AUTH_SAVE_DATA } from "../actions/auth";

const iniState = {
    userData: {},
    token: null
}
const authReducer = (state = iniState, action) => {
    switch (action.type) {
        case AUTH_SAVE_DATA:
            return {
                ...state,
                userData: action.data.user,
                token: action.data.jwt
            }
        case AUTH_CLEAR_DATA:
            return iniState
        default:
            return state;
    }
}

export default authReducer;