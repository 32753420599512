import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { cartAdd } from '../redux/actions/cart';
import { getAPIurl } from '../utils/environment';

const VoucherCard = ({vouch}) => {

    let dispatch = useDispatch()

    const addToCart = (e,id) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(cartAdd(id, 1))
    };

let mainImg = vouch.gallery.filter((img, idx) => idx === 0)[0]
return (                            
<div className="grid" key={vouch.id}>
    <div className="img-cart">
        {mainImg && mainImg.formats.medium && 
        <div className="img-holder">
        <img src={getAPIurl()+mainImg.formats.medium.url} alt={mainImg.alternativeText}/>
        </div>}
        <div className="cart-details">
            <ul><li>
            {vouch.availability > 0 ?
                <a href="/#" className="theme-btn-s8" onClick={(e) => addToCart(e,vouch.id)}>Do košíku</a>
                :
                <div className="alert alert-danger">Vyprodáno!</div>  
            }
            </li></ul>
        </div>
    </div>
    <div className="details">
        <h4><Link to={"/dobrovoucher/"+vouch.url}>{vouch.name}</Link></h4>
        <span className="price">{vouch.price} Kč</span>
    </div>
</div>)

}

export default VoucherCard;