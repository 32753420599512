import { gql } from '@apollo/client';

const LOCALITY_FIELDS = gql`
  fragment LocalityFields on Locality {
    id
    name
    url
  }
`;

const LOCALITY_LIST = gql`
  ${LOCALITY_FIELDS}
  query getLocalityList {
    localities {
      ...LocalityFields
    }
  }
`;


export {LOCALITY_LIST}