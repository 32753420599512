import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { authSaveData } from '../redux/actions/auth';
import { getAPIurl } from '../utils/environment';

const LoginProviderRedirect = (props) => {

  let location = useLocation();
  const [doRedirect, setDoRedirect] = useState(false);
  let dispatch = useDispatch()

  useEffect(() => {

    fetch(`${getAPIurl()}/auth/${props.provider}/callback${location.search}`)
      .then(res => {
        if (res.status !== 200) {
          alert('Chyba přihlášení');
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        dispatch(authSaveData(res))
        setDoRedirect(true)
      })
      .catch(err => {
        console.log(err);
        alert('Chyba přihlášení');
      });
  }, [location.search, props.provider, dispatch]);  

  return (
    <>
      {doRedirect && <Redirect to="/" />}
    </>
  );
}

export default LoginProviderRedirect;
