import React, { useEffect } from 'react';
import Preloader from './Preloader';

const ExternalRedirect = ({url}) => {

    useEffect(() => {
        window.location.replace(url)
    }, [url])
    return (
        <Preloader />
    )

  }

export default ExternalRedirect;