import React from 'react';
import StoriesCarouselBlock from '../blocks/StoriesCarouselBlock';
import PageTitle from '../components/layout/PageTitle';

const StoryList = () => {

    return (
        <>
        <PageTitle title={"DOBROdruzi"} crumbs={[{title: "DOBROdruzi", url: "/pribehy"}]}/>

        <section className="blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-10 col-md-offset-1">
                        <div className="blog-content">
                            <div className="post">
                                <p>
                                Ve svém životě a vztazích objevují nové cesty rovnováhy, harmonie a zdraví. Jejich příběhy jsou pestré a přístup celostní. Podílejí se na DOBROčinných aktivitách a křídlí. 
                                DOBRO nelze šířit jinak než osobně, bytím ve svém vlastním středu a přirozenosti. Pojďte se poznat, nikdy nejste sami!
                                </p>
                                <blockquote><p>
                                Naděje není přesvědčení, že něco dobře dopadne, ale jistota, že má něco smysl - bez ohledu na to, jak to dopadne.<br /><i>Václav Havel</i>
                                </p></blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="causes-section causes-section-s2 section-padding-bottom">
            <div className="container-fluid">
                <StoriesCarouselBlock />
            </div>
        </section>
        </>
    );
}

export default StoryList;
