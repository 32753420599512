import React from 'react';
import CartAddModal from '../CartAddModal';
import Footer from './Footer';
import Header from './Header';

const Main = (props) => {

  return (
    <>
        <Header />
        {props.children}
        <CartAddModal />
        <Footer />
    </>
  )
}

export default Main