import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cartAddVisited } from '../redux/actions/cart';

const CartAddModal = () => {

    let {cartAdded} = useSelector(mapStateToProps) 
    let [modalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        if(cartAdded) {
            setModalVisible(true)
            dispatch(cartAddVisited())
        }
    }, [cartAdded, dispatch]);  

    return (
        <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Voucher byl vložen do košíku</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Můžete pokračovat výběrem dalších voucherů nebo přejít do košíku k objednání.
            </p>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setModalVisible(false)} className="theme-btn-s3" style={{marginRight: 20}}>
                <i className="fi flaticon-back"></i>
                Pokračovat v nákupu
            </button>
            <Link to="/kosik" className="theme-btn-s6" onClick={() => setModalVisible(false)}>
                <i className="fi flaticon-shopping-basket"></i>
                Košík
            </Link>
          </Modal.Footer>
        </Modal>
        )

  }

const mapStateToProps = (state) => {
    return {
        cartAdded: state.cart.added
    }
}

export default CartAddModal;
