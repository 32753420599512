import React from 'react';
import Homepage from './pages/Homepage'

import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import VoucherDetail from './pages/VoucherDetail';
import VoucherList from './pages/VoucherList';
import VoucherTopList from './pages/VoucherTopList';
import Main from './components/layout/Main';
import Cart from './pages/Cart';
import ArticleDetail from './pages/ArticleDetail';
import Registration from './pages/Registration';
import Login from './pages/Login';
import LoginProviderRedirect from './pages/LoginProviderRedirect';
import Order from './pages/Order';
import Partners from './pages/Partners';
import StoryList from './pages/StoryList';

const App = () => {

  return (
    <Router>
        <div className="page-wrapper">
            <Main>
                <Switch>
                    <Route path="/dobrovouchery/:org?/:loc?">
                        <VoucherList />
                    </Route>                
                    <Route path="/top-dobrovouchery">
                        <VoucherTopList />
                    </Route>                
                    <Route path="/dobrovoucher/:url">
                        <VoucherDetail />
                    </Route>
                    <Route path="/clanek/:url">
                        <ArticleDetail />
                    </Route>
                    <Route path="/pribeh/:url">
                        <ArticleDetail />
                    </Route>
                    <Route path="/pribehy">
                        <StoryList />
                    </Route>
                    <Route path="/objednavka/:id/:transid?/:status?">
                        <Order />
                    </Route>                    
                    <Route path="/kosik">
                        <Cart />
                    </Route>
                    <Route path="/partneri">
                        <Partners />
                    </Route>                    
                    <Route path="/registrace">
                        <Registration />
                    </Route>
                    <Route path="/prihlaseni">
                        <Login />
                    </Route>
                    <Route path="/prihlaseni-facebook">
                        <LoginProviderRedirect provider="facebook" />
                    </Route>
                    <Route path="/">
                        <Homepage />
                    </Route>
                </Switch>
            </Main>                    
        </div>
    </Router>
  );
}

export default App;
